import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import CreateAccount from "../../views/createAccount";
import Login from "../../views/login";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import AboutUs from "../../views/aboutUs";
import Ride from "../../views/ride";
import RideDetails from "../../views/RideDetails";

import PrivacyPolicy from "../../views/privacyPolicy";
import TermsConditions from "../../views/termsConditions";
import ProtectedRoute from "../../views/protectedRoute";
import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import Help from "../../views/help";
import Dashboard from "../../views/dashboard";
import PaymentLogs from "../../views/paymentLogs";
import PaymentDetails from "../../views/paymentLogs/paymenDetails";
import BusinessHoursManagement from "../../views/businessHoursManagement";
import Notifications from "../../views/notifications/";
import VehicleCreation from "../../views/vehicleCreation";
import VehicleView from "../../views/vehicleView";
import VehicleEdit from "../../views/vehicleView/vehicleEdit";
// import Notifications from "../../views/notifications";
import socket from '../socket/index';
import { useEffect } from "react";
import { useSelector } from "react-redux";

const MyRouter = () => {
  const token = useSelector((state) => state.user.userToken);
  const user = useSelector((state) => state.user.userData);
  useEffect(()=>{
    if(token){
      socket.emit('setupDriver', user);
    }
  },[])
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/createAccount"
          index
          element={
            // <ClientLayout
            //   head={{ title: "Dashboard", description: "Some Description." }}
            //   headerStyle={{ height: { base: "40px", md: 14 } }}
            // >
              <CreateAccount />
            // </ClientLayout>
          }
        />
        <Route
          path="/VehicleCreation"
          index
          element={
            // <ClientLayout
            //   head={{ title: "Dashboard", description: "Some Description." }}
            //   headerStyle={{ height: { base: "40px", md: 14 } }}
            // >
            <VehicleCreation />
            // </ClientLayout>
          }
        />
        <Route
          path="/vehicleView"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
            <VehicleView />
             </ClientLayout>
          }
        />
        <Route
          path="/vehicleEdit"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
            <VehicleEdit />
             </ClientLayout>
          }
        />

        <Route path="/login" index element={<Login />} />
        <Route
          path="/forget-password-1"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword1 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-2"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword2 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-3"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword3 />
            </ClientLayout>
          }
        />
        <Route
          path="/aboutUs"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <AboutUs />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ride"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Ride />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/rideDetails/:id"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <RideDetails />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/privacyPolicy"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PrivacyPolicy />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/termsConditions"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <TermsConditions />
            </ClientLayout>
          }
        />
        <Route
          path="/profile"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
            head={{ title: "Dashboard", description: "Some Description." }}
            headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Profile />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/editProfile"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <EditProfile />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/changePassword"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ChangePassword />
            </ClientLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/help"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Help />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Dashboard />
            </ClientLayout>
             </ProtectedRoute>
          }
        />
        <Route
          path="/paymentLogs"
          index
          element={
            <ProtectedRoute>

            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PaymentLogs />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymentLogs/:id"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PaymentDetails />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/businessHoursManagement"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <BusinessHoursManagement />
            </ClientLayout>
          }
        />

        <Route
          path="/notifications"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Notifications />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
