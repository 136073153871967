import { useState } from "react";
import { Col, Row, Card, Button, Rate, Steps } from "antd";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import DashbordSidebar from "../../components/DashboardSidebar";
// import image1 from "../../assets/gloves.png";
// import { myordersData } from "../../components/Data/data";
import { reviews } from "../../components/Data/data";
import { AiFillEnvironment } from "react-icons/ai";
import mypetimg from "../../assets/testi-profile.png";
// import pet2 from "../../assets/pet2.png";
const { Step } = Steps;

const DropzoneFiltercards = () => {
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  //   const [orderDetails, setOrderDetails] = useState(null);
  const [products, setProducts] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  // const [myorders, setMyorders] = useState(
  //   myordersData.find((item) => item.id == id)
  // );
  //   useEffect(() => {
  //     getOrderDetails();
  //   }, []);
  //   const getOrderDetails = () => {
  //     Get(`${ORDER.getOrder}${id}`, token)
  //       .then((response) => {
  //         setOrderDetails(response?.data);
  //         const { products } = response?.data;
  //         const updatedProducts = products.map((product) => ({
  //           ...product,
  //           ...product.product,
  //           totalPrice: product.price * product.quantity,
  //         }));
  //         setProducts(updatedProducts);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log("Error fetching order details", err);
  //         setLoading(false);
  //       });
  //   };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Payment Logs - Ride history</h3>
                      </div>
                      <div className="bg-paren">
                        <Row justify="center">
                          <Col xl={23} lg={23} md={22} xs={23}>
                            <Card
                              className="orderDetails"
                              style={{ padding: "20px 0" }}
                            >
                              <Row gutter={30} style={{ marginBottom: "50px" }}>
                                <Col lg={12} md={24} xs={24}>
                                  <div className="stepper d-flex flex-column mt-4 ml-2 details-card">
                                    <Row gutter={[16, 16]}>
                                      <Col lg={12} md={8} sm={12} xs={24}>
                                        <div className="for-flex">
                                          <img
                                            src={mypetimg}
                                            alt=""
                                            className="img-fluid"
                                            style={{ width: "50px" }}
                                          />
                                          <div>
                                            <h6 className="f-16-black">
                                              John Doe
                                            </h6>
                                            <p className="f-14">Sep 20, 2023</p>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col
                                        lg={12}
                                        md={8}
                                        sm={12}
                                        xs={24}
                                        style={{ textAlign: "end" }}
                                      >
                                        <h6 className="f-16-black">
                                          Estimated Fare: $50
                                        </h6>
                                        <p className="f-14">Time: 13:00:00</p>
                                      </Col>
                                    </Row>
                                    <Steps
                                      direction="vertical"
                                      size="small"
                                      current={0}
                                      className="mb-1"
                                      style={{ padding: "18px 0" }}
                                    >
                                      <Step
                                        title="Pickup Location"
                                        icon={<AiFillEnvironment />}
                                        description="Abc road, 123 Street New York"
                                      />
                                      <Step
                                        title="Destination"
                                        icon={<AiFillEnvironment />}
                                        description="4264 Nelm Street, 123 Street Chicago"
                                      />
                                    </Steps>
                                  </div>
                                </Col>
                              </Row>

                              <Row
                                justify="center"
                                style={{ padding: "38px 0" }}
                              >
                                <Col lg={24} xs={23}>
                                  <h3 className="f-36">Reviews & Rating</h3>
                                  {reviews.map((reviews, index) => (
                                    <div key={index} className="review-box">
                                      <div className="review-sugg">
                                        <div className="for-media-object">
                                          <div className="flex-shrink-0">
                                            <span>
                                              {reviews.reviewsProfile}
                                            </span>
                                          </div>
                                          <div className="flex-grow-1 ms-3">
                                            <div className="star-18">
                                              <Rate
                                                allowHalf
                                                defaultValue={reviews.rating}
                                                disabled
                                              />
                                            </div>
                                            <h6>{reviews.reviewName}</h6>
                                          </div>
                                        </div>
                                      </div>
                                      <h6>{reviews.great}</h6>
                                      <p>{reviews.reviewDiscretion}</p>
                                    </div>
                                  ))}
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
