import { useEffect, useMemo, useState } from "react";
import {
  Col,
  Row,
  Card,
  Steps,
  Switch,
  Tabs,
  Button,
  Image,
  Table,
  Skeleton,
  Pagination,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DashbordSidebar from "../../components/DashboardSidebar";
// import image1 from "../../assets/gloves.png";
import { myordersData } from "../../components/Data/data";
import { AiFillEnvironment } from "react-icons/ai";
import mypetimg from "../../assets/pet2.png";
import elp from "../../assets/testi-profile.png";
import { Post } from "../../config/api/post";
import { Put } from "../../config/api/put";
import { DRIVER, RIDE, UPLOADS_URL } from "../../config/constants/api";
import swal from "sweetalert";
import { paymentData } from "../../components/Data/data";
import { extractTime, formatTime } from "../../config/helpers";
import { AiOutlineEye } from "react-icons/ai";
import dayjs from "dayjs";

import { addUser } from "../../redux/slice/authSlice";
import { Get } from "../../config/api/get";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const { Step } = Steps;

const DropzoneFiltercards = () => {
  const token = useSelector((state) => state.user.userToken);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const [ongoingRide, setOngoingRide] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [rides, setRides] = useState(null);
  const index = location?.state?.index;
  const [isOnline, setIsOnline] = useState(userData.isOnline); // Initialize with the current state if available
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState("1");
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  const getOngoingRide = async () => {
    Get(RIDE.getDriverOngoingRide, token)
      .then((response) => {
        if (response?.status) {
          setOngoingRide(response?.data);
        }
      })
      .catch((err) => {
        console.log("Error Fetching Ongoing Ride", err);
      });
  };
  useEffect(() => {
    if (tabIndex === "1") {
      getOngoingRide();
    }
  }, []);
  useEffect(() => {
    if (index) {
      setTabIndex(index);
      getRides(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        "PENDING"
      );
    }
  }, [index]);

  const getRides = async (pageNumber, pageSize, status, driverId) => {
    Get(RIDE.getUpcomingOrCompletedRides, token, {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      status,
      driverId,
    })
      .then((response) => {
        if (response?.status) {
          setRides(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  // toggle online offline
  const handleChange = (val) => {
    Post(DRIVER.toggleActivity, {}, token)
      .then((response) => {
        if (response?.status) {
          swal("System Alert", response?.message, "success");
          dispatch(addUser({ user: response.data, token: token }));
          setIsOnline(!isOnline);
        }
      })
      .catch((e) => {
        let message = e?.response?.data?.message
          ? e?.response?.data?.message
          : e?.message;
        console.log(":::;", e);
        if (message) swal("Oops!", message, "error");
      });
  };

  // To Update Ride Status
  const handleRideStatus = (status) => {
    Put(`${RIDE.changeRideStatus}${ongoingRide?.ride?._id}`, token, { status })
      .then((response) => {
        if (response?.status) {
          swal("System Alert", response?.message, "success");
          if (
            response?.data?.status === "ENDED" ||
            response?.data?.status === "COMPLETED"
          ) {
            setOngoingRide(null);
            getRides(
              paginationConfig?.pageNumber,
              paginationConfig?.limit,
              "COMPLETED",
              userData?._id
            );
            setTabIndex("3");
          } else {
            getOngoingRide();
          }
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });

    // getOrders(pageNumber);
  };
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;
  const columns = [
    {
      title: "S. No.	",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (value, item, index) => (index < 9 && "0") + (index + 1),
    },
    {
      title: "Pickup Location",
      dataIndex: ["pickupLocation", "address"],
      key: "address",
      render: (item) => (item?.length > 35 ? item?.slice(0, 35) + "..." : item),
    },
    {
      title: "Dropoff Location",
      dataIndex: ["dropoffLocation", "address"],
      key: "address",
      render: (item) => (item?.length > 35 ? item?.slice(0, 35) + "..." : item),
    },
    {
      title: "Customer name",
      dataIndex: "passengerName",
      key: "passengerName",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (item) => <span>{dayjs(item).format("M/D/YYYY")}</span>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (item) => <span>{extractTime(item)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => (
        <>
          {item === "PENDING" ? (
            <span style={{ color: "#DD9F00" }}>{item}</span>
          ) : item === "STARTED" ? (
            <span style={{ color: "#2D308B" }}>{item}</span>
          ) : item === "ARRIVED" ? (
            <span style={{ color: "#2D308B" }}>{item}</span>
          ) : item === "ACCEPTED" ? (
            <span style={{ color: "#2D308B" }}>{item}</span>
          ) : item === "ENDED" ? (
            <span style={{ color: "#00D640" }}>{item}</span>
          ) : item === "COMPLETED" ? (
            <span style={{ color: "#00D640" }}>{item}</span>
          ) : (
            <span>{item}</span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (value, item, index) => (
        <AiOutlineEye
          style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
          onClick={() =>
            navigate("/rideDetails/" + value, { state: { ride: item } })
          }
        />
      ),
    },
  ];
  const onChange = (key) => {
    setTabIndex(key);
    if (Number(key) === 2) {
      getRides(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        "PENDING"
      );
    } else if (Number(key) === 3) {
      getRides(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        "COMPLETED",
        userData?._id
      );
    }
  };
  const items = [
    {
      key: "1",
      label: "Ongoing Ride",
      children: (
        <Row gutter={30} style={{ marginBottom: "50px" }}>
          <Col lg={24} md={24} xs={24}>
            {ongoingRide ? (
              <div>
                <Row gutter={[16, 16]} justify={"center"}>
                  <Col lg={24} md={24} xs={24} style={{ textAlign: "end" }}>
                    <div className="pet-name-pic">
                      <div>
                        <h6 className="f-16-black">
                          Estimated Fare: ${ongoingRide?.ride?.fare}
                          {/* Estimated Fare: $20 */}
                        </h6>
                        <p className="web-p">
                          Time: {extractTime(ongoingRide?.ride?.time)}
                        </p>
                        {/* <p className="web-p">Time: 12:00:00 pm</p> */}
                      </div>
                    </div>
                  </Col>
                  <Col lg={18} md={20} xs={24} style={{ textAlign: "center" }}>
                    <Image
                      src={UPLOADS_URL + ongoingRide?.profile?.image}
                      alt=""
                      className="img-fluid"
                      style={{ maxWidth: "220px", maxHeight: "220px" }}
                      preview={false}
                    />
                    <h5>
                      {ongoingRide?.ride?.user?.firstName +
                        " " +
                        ongoingRide?.ride?.user?.lastName}
                    </h5>
                    {/* <h5>John Doe</h5> */}
                  </Col>
                  <Col lg={18} md={20} xs={24}>
                    <Steps
                      direction="vertical"
                      size="small"
                      current={0}
                      className="mb-1"
                      style={{ padding: "18px 0" }}
                    >
                      <Step
                        title="Pickup Location"
                        icon={<AiFillEnvironment />}
                        // description="Abc road, 123 Street New York"
                        description={ongoingRide?.ride?.pickupLocation?.address}
                      />
                      <Step
                        title="Destination"
                        icon={<AiFillEnvironment />}
                        // description="4264 Nelm Street, 123 Street Chicago"
                        description={
                          ongoingRide?.ride?.dropoffLocation?.address
                        }
                      />
                    </Steps>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {ongoingRide?.ride?.status === "ACCEPTED" && (
                        <>
                          <Button
                            className="web-btn"
                            onClick={() => {
                              handleRideStatus("ARRIVED");
                            }}
                          >
                            Arrive
                          </Button>
                          <Button
                            className="web-btn2"
                            onClick={() => {
                              handleRideStatus("STARTED");
                            }}
                          >
                            Start Ride
                          </Button>
                        </>
                      )}
                      {ongoingRide?.ride?.status === "ARRIVED" && (
                        <>
                          <Button
                            className="web-btn"
                            onClick={() => {
                              handleRideStatus("STARTED");
                            }}
                          >
                            Start Ride
                          </Button>
                          <Button
                            className="web-btn"
                            onClick={() => {
                              handleRideStatus("ENDED");
                            }}
                          >
                            End Ride
                          </Button>
                        </>
                      )}
                      {ongoingRide?.ride?.status === "STARTED" && (
                        <Button
                          className="web-btn"
                          onClick={() => {
                            handleRideStatus("ENDED");
                          }}
                        >
                          End Ride
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <Row gutter={[16, 16]}>
                  <Col
                    lg={24}
                    md={24}
                    xs={24}
                    style={{ textAlign: "end" }}
                  ></Col>

                  <Col lg={24} md={24} xs={24}>
                    <p
                      className="text-26"
                      style={{ textAlign: "center", margin: "100px 0px" }}
                    >
                      No Ongoing Ride to show
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "Requested Rides",
      children: (
        <>
          <Row style={{ padding: 20, overflow: "auto" }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Skeleton active />
                <br />
              </div>
            ) : (
              <Table
                className="styledTable"
                dataSource={rides}
                columns={columns}
                pagination={false}
              />
            )}
          </Row>
          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>
              <p>{message}</p>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                className="styledPagination"
                onChange={(e) => handlePageChange(e)}
                current={parseInt(paginationConfig.pageNumber)}
                pageSize={paginationConfig.limit}
                total={paginationConfig.totalDocs}
                itemRender={itemRender}
              />
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "3",
      label: "Completed Rides",
      children: (
        <>
          <Row style={{ padding: 20, overflow: "auto" }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Skeleton active />
                <br />
              </div>
            ) : (
              <Table
                className="styledTable"
                dataSource={rides}
                columns={columns}
                pagination={false}
              />
            )}
          </Row>
          <Row style={{ padding: "10px 20px" }}>
            <Col xs={24} md={12}>
              <p>{message}</p>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Pagination
                className="styledPagination"
                onChange={(e) => handlePageChange(e)}
                current={parseInt(paginationConfig.pageNumber)}
                pageSize={paginationConfig.limit}
                total={paginationConfig.totalDocs}
                itemRender={itemRender}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div
                      className="about-us-section"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="bg-paren" style={{ width: "100%" }}>
                        <Card
                          className="orderDetails"
                          style={{ padding: "20px 0" }}
                        >
                          <Row gutter={[16, 16]}>
                            <Col
                              lg={24}
                              md={24}
                              xs={24}
                              style={{
                                textAlign: "end",
                                marginBottom: "10px",
                              }}
                            >
                              <Switch
                                checkedChildren="Online"
                                unCheckedChildren="Offline"
                                onChange={handleChange}
                                checked={isOnline}
                                disabled={ongoingRide}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={24}>
                              <Tabs
                                activeKey={tabIndex}
                                // defaultActiveKey={tabIndex}
                                items={items}
                                onChange={onChange}
                              />
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
