import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Row,
  Button,
  theme,
  Form,
  Input,
  message,
  Upload,
  Image,
  Select,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, DRIVER, UPLOADS_URL } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import Logo from "../../assets/logo-header.png";
import numplate from "../../assets/numplate.png";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Get } from "../../config/api/get";

function CreateAccount() {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageObject, setImageObject] = useState(null);

  const handleChange2 = () => {
    swal("", "Congrats! You have been registered successfully", "success");
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const userToken = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const { token } = theme.useToken();
  const getDriverVehicle = () => {
    Get(`${DRIVER.getDriverVehicle}${user?._id}`, userToken)
      .then((response) => {
        if (response.status) {
          console.log(response?.data);
          setVehicleDetails(response?.data);
        }
      })
      .catch((err) => {
        
        let message = err?.response?.data?.message
        if(message==='Vehicle Not Found'){
          navigate('/vehicleCreation')
        }
        console.log("Error fetching Profile Information ", err);
      });
  };
  useEffect(() => {
    getDriverVehicle();
  }, []);

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      {vehicleDetails && (
        <div className="auth-banner">
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col xs={23} md={18} lg={15}>
              <div className="auth-box">
                <div className="blur-bg-inner-card-form">
                  <h2 className="auth-heading" style={{ padding: "30px 0" }}>
                    Vehicle Registration
                  </h2>

                  <Row
                    style={{ width: "100%" }}
                    gutter={[16, 16]}
                    className="row-res-margin-0"
                  >
                    <Col lg={12} md={12} xs={24}>
                      <label>
                        Vehicle Type
                      </label>
                      <p className="web-p">{vehicleDetails?.type}</p>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <label>
                        Brand Name
                      </label>
                      <p className="web-p">{vehicleDetails?.brandName}</p>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <label>
                        Model Name
                      </label>
                      <p className="web-p">{vehicleDetails?.modelName}</p>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <label>
                        Registration Year
                      </label>
                      <p className="web-p">{vehicleDetails?.regYear}</p>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <label>
                        License Plate Number
                      </label>
                      <p className="web-p">{vehicleDetails?.licenseNo}</p>
                    </Col>
                    <Col lg={24} md={24} xs={24}>
                      <label>
                        License Image
                      </label>
                      <div>
                        <Image
                          preview={false}
                          alt={"Failed to load image"}
                          src={UPLOADS_URL + vehicleDetails?.image}
                        />
                      </div>
                    </Col>
                    <div
                      className=""
                      style={{ textAlign: "center", margin: "10px auto" }}
                    >
                      <Button
                        type=""
                        htmlType="submit"
                        className="btn web-btn px-5"
                        onClick={() => navigate("/vehicleEdit" , {state : {vehicleDetails}})}
                      >
                        Edit Now
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
}

export default CreateAccount;
