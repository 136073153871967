import React, { useState } from "react";
import {
  Layout,
  Col,
  Row,
  Button,
  theme,
  Form,
  Input,
  message,
  Upload,
  Image,
  Select,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, DRIVER } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import Logo from "../../assets/logo-header.png";
// import hand from "../../assets/hand.png";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import swal from "sweetalert";
import { Link } from "react-router-dom";

function CreateAccount() {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageObject, setImageObject] = useState(null);
  const userToken = useSelector((state) => state.user.userToken);
  const handleChange2 = () => {
    swal("", "Congrats! You have been registered successfully", "success");
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const { token } = theme.useToken();

  const onFinish = (values) => {
    if (!imageObject) {
      swal("Error", "Profile Image is Required", "error");
      return;
    }
    setLoading(true);
    let data = new FormData();
    const { type, brandName, modelName, regYear, licenseNo } = values;
    data.append("type", type);
    data.append("brandName", brandName);
    data.append("modelName", modelName);
    data.append("regYear", regYear);
    data.append("licenseNo", licenseNo);
    data.append("image", imageObject);
    Post(DRIVER.registerVehicle, data, userToken, null, "multipart")
      .then((response) => {
        setLoading(false);
        if (response?.status) {
          swal("Syestem Alert", response?.message, "success");
          navigate("/vehicleView");
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleChangepro = (info) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        setImageObject(
          info?.fileList[info?.fileList?.length - 1]?.originFileObj
        );
        setLoading(false);
        setImageUrl(url);
      }
    );
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Invalid Uplaod, You can only upload image files!");
    }
    return isImage;
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Image
      </div>
    </button>
  );

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="auth-banner">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={15}>
            <div className="auth-box">
              <div className="blur-bg-inner-card-form">
                <div style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    width={70}
                    height={70}
                    src={Logo}
                    style={{ maxWidth: 100 }}
                  />
                </div>
                <h2 className="auth-heading">Vehicle Registration</h2>
                <Form
                  className="row g-3"
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row
                    className="row-res-margin-0"
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    gutter={[16, 16]}
                  >
                    <Col lg={16} md={20} xs={24}>
                      <p
                        className="web-p"
                        style={{ color: "#616161", paddingTop: "5px" }}
                      >
                        Please register your vehicle. You will be able to view
                        your registered vehicle details here.
                      </p>
                    </Col>
                  </Row>
                  <Row
                    style={{ width: "100%", justifyContent: "center" }}
                    gutter={[16, 16]}
                    className="row-res-margin-0"
                  >
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item label="Vehicle Type" name="type">
                        <Select placeholder="Select">
                          <Select.Option value="ECONOMIC">
                            Economic
                          </Select.Option>
                          <Select.Option value="ECOPLUS">Ecoplus</Select.Option>
                          <Select.Option value="PLUSPLUS">
                            Plusplus
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Brand Name"
                        name="brandName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Brand Name!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Brand Name"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Model Name"
                        name="modelName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Model Name!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Model Name"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Registration Year"
                        name="regYear"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Registration Year!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter registration year of your vehicle"
                          className="web-input"
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={24} md={24} xs={24}>
                      <Form.Item
                        label="License Number"
                        name="licenseNo"
                        rules={[
                          {
                            required: true,
                            message: "Please enter License Number!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter License Number"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={24} md={24} xs={24}>
                      <Form.Item
                        label="License Image"
                        name="upload"
                        rules={[
                          {
                            required: true,
                            message: "Please Upload Image!",
                          },
                        ]}
                        className="vical-rigstr"
                      >
                        <Upload
                          name="image"
                          showUploadList={false}
                          style={{ position: "relative" }}
                          onChange={handleChangepro}
                          beforeUpload={beforeUpload}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "150px",
                              border: "1px dotted gray",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              borderRadius: "10px",
                            }}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  maxWidth: "100%",
                                  height: "140px",
                                  objectPosition: "center",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </div>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <div
                      className=""
                      style={{ textAlign: "center", margin: "2px 5px" }}
                    >
                      <Button
                        type=""
                        htmlType="submit"
                        className="btn web-btn px-5"
                        onClick={() => navigate("/")}
                      >
                        Later
                      </Button>
                    </div>
                    <div
                      className=""
                      style={{ textAlign: "center", margin: "2px 5px" }}
                    >
                      <Button
                        type=""
                        htmlType="submit"
                        className="btn web-btn px-5"
                        // onClick={() => navigate("/")}
                      >
                        Register vehicle
                      </Button>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default CreateAccount;
