import { useEffect, useState } from "react";
import { Col, Row, Button } from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { COMPANY, UPLOADS_URL } from "../../../config/constants/api";
import DashbordSidebar from "../../../components/DashboardSidebar";
import profilepic from "../../../assets/profileimg.png";
import { extractDate } from "../../../config/helpers";
import { Get } from "../../../config/api/get";

const DropzoneFiltercards = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const getMyAccount = () => {
    Get(COMPANY.getMyAccount, token)
      .then((response) => {
        if (response.status) {
          setAccountDetails(response?.data);
        }
      })
      .catch((err) => {
        console.log("Error fetching Profile Information ", err);
      });
  };
  // useEffect(() => {
  //   getMyAccount();
  // }, []);

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-parent">
                        <h3 className="main-heading">My Profile</h3>
                        <Row
                          gutter={[16, 16]}
                          align={"middle"}
                          justify={"space-between"}
                        >
                          <Col md={10} lg={10} xl={8}>
                            <div className="wrapper-group-1000001858">
                              <img
                                src={UPLOADS_URL + userData?.image}
                                // src={profilepic}
                                alt=""
                                className="img-fluid"
                                style={{
                                  maxHeight: "350px",
                                  objectFit: "cover",
                                  objectPosition: "center",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={14} lg={14} xl={14}>
                            <div className="">
                              <div className="logo-rectangle">
                                <div className="profile-info">
                                  <div className="full-name">
                                    <div className="jake-dawson">
                                      <div className="phone-number">
                                        <div className="full-name1">
                                          <p className="full-name2">
                                            Full Name
                                          </p>
                                        </div>
                                        <div className="jake-dawson1">
                                          {/* Jake Dawson */}
                                          {userData?.firstName + ' ' + userData?.lastName}
                                        </div>
                                      </div>
                                      <div className="gender">
                                        <div className="phone-number1">
                                          Phone Number
                                        </div>
                                        <div className="frame-parent">
                                          <div className="a-b-c">
                                            {userData?.mobile}
                                            {/* {profileDetails?.phone} */}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="location">Location</div> */}
                                    </div>
                                    <div className="changepassword">
                                      <div className="b-g">
                                        <div className="email">Email</div>
                                        <div className="jakesamplecom">
                                          {userData?.email}
                                          {/* {profileDetails?.email} */}
                                        </div>
                                      </div>
                                      <div className="b-g1">
                                        <div className="gender1">Gender</div>
                                        <div className="male">
                                          {userData?.gender}
                                          {/* {profileDetails?.gender} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* ABC Location, Town, City, Country */}
                                  {/* {profileDetails?.location && (
                                    <div className="abc-location-town">
                                      {profileDetails?.location?.street +
                                        ", " +
                                        profileDetails?.location?.state +
                                        ", " +
                                        profileDetails?.location?.city +
                                        ", " +
                                        profileDetails?.location?.country}
                                    </div>
                                  )} */}
                                </div>
                                <div className="f-a-qs">
                                  <div className="career">
                                    <div className="date-of-birth">
                                      Date Of Birth
                                    </div>
                                    {/* <div className="sign-waiver">
                                        Sign Waiver
                                      </div> */}
                                  </div>
                                  <div className="termsconditions">
                                    <div className="jan-28-1998">
                                     {extractDate(userData?.dateOfBirth)}
                                      {/* {extractDate(profileDetails?.dateOfBirth)} */}
                                    </div>
                                    {/* <div className="abc-waiverpdf-parent">
                                        <div className="abc-waiverpdf">
                                          {myprofiledata.signWaiver}
                                        </div>
                                        <div className="ellipse-parent">
                                          <div className="frame-item" />
                                          <img
                                            className="frame-inner"
                                            loading="eager"
                                            alt=""
                                            src="/group-1000002368.svg"
                                          />
                                        </div>
                                      </div> */}
                                  </div>
                                </div>
                                <div className="frame-group">
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{ marginBottom: "10px" }}
                                      className="web-btn"
                                      onClick={() => navigate("/editProfile")}
                                    >
                                      Edit Profile
                                    </Button>
                                  </div>
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{ marginBottom: "10px" }}
                                      className="web-btn"
                                      onClick={() =>
                                        navigate("/changePassword")
                                      }
                                    >
                                      Change password
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
